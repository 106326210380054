import { Controller } from "react-hook-form";

import { Button, CustomDatepicker, Calculate, Modal } from "../../Components";
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import { AddressSuggestions } from "react-dadata";

import style from "./order.module.scss";
import { toast } from "react-toastify";
import { useState } from "react";

const CreateOrder = ({
  formData,
  setFormData,
  handleOnChange,
  onSubmit,
  register,
  handleSubmit,
  control,
  errors,
  city,
  user,
  promo,
  comission,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isAdress, setIsAdress] = useState([]);

  return (
    <div className={style.order}>
      <h1>Заказ</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={(e) => handleOnChange(e)}
        className={style.form}
      >
        {!user ? (
          <>
            <h2>Контактные данные заказчика:</h2>

            <div className={style.form_group}>
              <div>
                <label>Фамилия:</label>
                <input
                  type="text"
                  {...register("customer_lastname", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  placeholder="Иванов"
                />
                {errors.customer_lastname && <p>Введите Вашу фамилию</p>}
              </div>
              <div>
                <label>Имя:</label>
                <input
                  type="text"
                  {...register("customer_firstname", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  placeholder="Петр"
                />
                {errors.customer_firstname && <p>Введите Ваше имя</p>}
              </div>
            </div>
            <div className={style.form_group}>
              <div>
                <label>Отчество:</label>
                <input
                  type="text"
                  {...register("customer_middlename", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  placeholder="Алексеевич"
                />
                {errors.customer_middlename && <p>Введите Ваше отчество</p>}
              </div>
              <div className={style.tel}>
                <label>Номер телефона:</label>
                <Controller
                  control={control}
                  name="customer_phone"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <PhoneInput
                        placeholder="+7 (999) 999-99-99"
                        onChange={onChange}
                        value={value}
                        country={"ru"}
                        disableDropdown={true}
                        inputProps={{
                          name: "customer_phone",
                        }}
                        inputStyle={{ fontFamily: "sans-serif" }}
                      />
                    </>
                  )}
                />
                {errors["customer_phone"] && <p>Введите Ваш номер телефона</p>}
              </div>
            </div>
            <div className={style.form_group}>
              <div>
                <label>Почта:</label>
                <input
                  {...register("email", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  type="email"
                  placeholder="ivaninf@yandex.com"
                  style={{
                    width: "100%",
                  }}
                />

                {errors.email && <p>Введите Вашу почту</p>}
              </div>
            </div>
          </>
        ) : null}
        <h2>Информация о доставке:</h2>

        <div className={style.form_selector}>
          <label>Когда доставить:</label>
          <div>
            <label>
              <input
                type="radio"
                name="time_delivery"
                value="Как можно скорее"
                defaultChecked
              />
              <div className="md:w-52">Как можно скорее</div>
            </label>
            <label>
              <input type="radio" name="time_delivery" value="Запланировать" />
              <div className="md:w-52">Запланировать</div>
            </label>
            <label>
              <input
                type="radio"
                disabled
                name="time_delivery"
                value="Интервал"
              />
              <div className="md:w-52">Интервал</div>
            </label>
            {formData.time_delivery === "Интервал" && (
              <div>
                <label>Укажите интервал:</label>
                <input
                  {...register("interval", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  type="text"
                  placeholder="01.01.2023 - 03.01.20223"
                />
                {errors.interval && <p>Укажите интервал даты</p>}
              </div>
            )}
          </div>
        </div>

        <div>
          {formData.time_delivery !== "Интервал" && (
            <div className={style.form_selector}>
              <label>Как доставить:</label>
              <div>
                <label>
                  <input
                    defaultChecked
                    type="radio"
                    name="how_delivery"
                    value="6"
                  />
                  <div className="md:w-52">Пешком</div>
                </label>
                <label>
                  <input type="radio" name="how_delivery" value="7" />
                  <div className="md:w-52">Легковой автомобиль</div>
                </label>
                <label>
                  <input type="radio" name="how_delivery" value="5" />
                  <div className="md:w-52">Грузовой автомобиль</div>
                </label>
              </div>
            </div>
          )}

          {formData.how_delivery === "6" &&
            formData.time_delivery !== "Интервал" && (
              <div className={style.form_selector}>
                <label>Вес посылки:</label>
                <div>
                  <label>
                    <input
                      defaultChecked
                      type="radio"
                      name="total_weight"
                      value="1"
                    />
                    <div>до 1 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="5" />
                    <div>до 5 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="10" />
                    <div>до 10 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="15" />
                    <div>до 15 кг</div>
                  </label>
                </div>
              </div>
            )}
          {formData.how_delivery === "7" &&
            formData.time_delivery !== "Интервал" && (
              <div className={style.form_selector}>
                <label>Вес посылки:</label>
                <div>
                  <label>
                    <input type="radio" name="total_weight" value="50" />
                    <div>до 50 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="100" />
                    <div>до 100 кг</div>
                  </label>
                  <label className="check option">
                    <input type="radio" name="total_weight" value="150" />
                    <div>до 150 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="200" />
                    <div>до 200 кг</div>
                  </label>
                </div>
              </div>
            )}
          {formData.how_delivery === "5" &&
            formData.time_delivery !== "Интервал" && (
              <div className={style.form_selector}>
                <label>Вес посылки:</label>
                <div>
                  <label>
                    <input type="radio" name="total_weight" value="500" />
                    <div>до 500 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="700" />
                    <div>до 700 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="1000" />
                    <div>до 1000 кг</div>
                  </label>
                  <label>
                    <input type="radio" name="total_weight" value="1500" />
                    <div>до 1500 кг</div>
                  </label>
                </div>
              </div>
            )}
        </div>

        <h2>Отправление:</h2>

        <div className={style.form_group}>
          <div>
            <label>От кого:</label>
            <input
              type="text"
              {...register("name_from", {
                required: true,
                validate: (value) => value !== "",
              })}
              placeholder="Иван"
            />
            {errors.name_from && <p>Введите Имя</p>}
          </div>
          <div className={style.tel}>
            <label>Номер телефона:</label>
            <Controller
              control={control}
              name="phone_from"
              rules={{
                required: true,
                onChange: (e) =>
                  setFormData({
                    ...formData,
                    phone_from:
                      e.target.value.lenght > 10
                        ? e.target.value.slice(0, -1)
                        : e.target.value,
                  }),
              }}
              render={({ field: { value, onChange } }) => (
                <>
                  <PhoneInput
                    placeholder="+7 999 999 99 99"
                    onChange={onChange}
                    value={value}
                    country={"ru"}
                    disableDropdown={true}
                    inputProps={{
                      name: "phone_from",
                    }}
                    inputStyle={{ fontFamily: "sans-serif" }}
                  />
                </>
              )}
            />
            {errors["phone_from"] && <p>Введите корректный номер телефона</p>}
          </div>
        </div>
        <div className={style.form_group}>
          <div className={style.adress}>
            {formData.time_delivery === "Запланировать" && (
              <>
                <label>Начать:</label>
                <CustomDatepicker
                  formData={formData}
                  setFormData={setFormData}
                  start
                />
              </>
            )}
            <label>Адрес:</label>
            <Controller
              control={control}
              name="adress_from"
              render={({ field }) => (
                <AddressSuggestions
                  {...register("adress_from", {
                    required: true,
                    validate: (value) => value !== "",
                    onChange: (e) => {
                      handleOnChange(e);
                      console.log(e);
                    },
                  })}
                  {...field}
                  count={5}
                  filterLocations={[
                    {
                      city,
                    },
                  ]}
                  onChange={(v) => {
                    field.onChange(v);
                    console.log(v);
                  }}
                  selectOnBlur={true}
                  suggestionClassName="sug"
                  suggestionsClassName="bg-white text-black border border-lightgray flex flex-col items-start p-5 rounded-lg absolute z-10 w-full shadow-sm -mt-8"
                  currentSuggestionClassName="cursug"
                  containerClassName="cont"
                  highlightClassName="highlight"
                  inputProps={{
                    placeholder: "г. Москва ул. Новый Арбат 12",
                    style: { width: "100%" },
                    name: "adress_from",
                  }}
                  token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
                />
              )}
            />
            {errors.adress_from && (
              <p>Укажите откуда забрать посылку в пределах города</p>
            )}
          </div>
        </div>
        <div className={style.form_group}>
          <div className="group_item">
            <label>Комментарий:</label>
            <textarea
              type="text"
              {...register("note_from")}
              placeholder="Укажите комментарий для курьера"
              rows={4}
            />
          </div>
        </div>

        <h2>Получение:</h2>

        <div className={style.form_group}>
          <div>
            <label>Кому:</label>
            <input
              type="text"
              {...register("name_where", {
                required: true,
                validate: (value) => value !== "",
              })}
              placeholder="Петр"
            />
            {errors.name_from && <p>Введите Имя получателя</p>}
          </div>
          <div className={style.tel}>
            <label>Номер телефона:</label>
            <Controller
              control={control}
              name="phone_where"
              rules={{
                required: true,
                onChange: (e) =>
                  setFormData({
                    ...formData,
                    phone_where:
                      e.target.value.lenght > 10
                        ? e.target.value.slice(0, -1)
                        : e.target.value,
                  }),
              }}
              render={({ field: { value, onChange } }) => (
                <>
                  <PhoneInput
                    placeholder="+7 (999) 999-99-99"
                    onChange={onChange}
                    value={value}
                    country={"ru"}
                    disableDropdown={true}
                    inputProps={{
                      name: "phone_where",
                    }}
                    inputStyle={{ fontFamily: "sans-serif" }}
                  />
                </>
              )}
            />
            {errors["phone_where"] && <p>Введите корректный номер телефона</p>}
          </div>
        </div>
        <div className={style.form_group}>
          <div className={style.adress}>
            {formData.time_delivery === "Запланировать" && (
              <>
                <label>Закончить:</label>
                <CustomDatepicker
                  formData={formData}
                  setFormData={setFormData}
                  end
                />
              </>
            )}
            <label>Адрес:</label>

            <Controller
              control={control}
              name="adress_where"
              render={({ field }) => (
                <>
                  <AddressSuggestions
                    // containerClassName="form_adress"
                    {...register("adress_where", {
                      required: true,
                      validate: (value) =>
                        value !== "" && value[2] !== formData.adress_from[2],
                      onChange: (e) => handleOnChange(e),
                    })}
                    {...field}
                    count={5}
                    filterLocations={[
                      {
                        city,
                      },
                    ]}
                    suggestionClassName="sug"
                    suggestionsClassName="bg-white text-black border border-lightgray flex flex-col items-start p-5 rounded-lg absolute z-10 w-full shadow-sm -mt-8"
                    currentSuggestionClassName="cursug"
                    containerClassName="cont"
                    highlightClassName="highlight"
                    inputProps={{
                      placeholder: "г. Москва ул. Новый Арбат 12",
                      style: { width: "100%" },
                      name: "adress_where",
                    }}
                    token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
                  ></AddressSuggestions>
                </>
              )}
            />
            {errors["adress_where"] && (
              <p>Укажите куда привезти посылку в пределах города</p>
            )}
          </div>
        </div>
        <div className={style.form_group}>
          <div>
            <label>Комментарий:</label>
            <textarea
              type="text"
              {...register("note_where")}
              placeholder="Укажите комментарий для курьера"
              rows={4}
            />
          </div>
        </div>

        <h2>Информация о грузе:</h2>
        <div className={style.form_group}>
          <div>
            <label>Что везем:</label>
            <input
              type="text"
              {...register("object", {
                required: true,
                validate: (value) => value !== "",
              })}
              placeholder="Лекарства, продукты, книги..."
            />
            {errors.object && <p>Укажите что будем везти</p>}
          </div>
        </div>
        <div className={style.form_group}>
          <div>
            <label>Примерные габариты груза:</label>

            <Controller
              control={control}
              name="size"
              rules={{ required: true }}
              render={({ field: { ref } }) => (
                <>
                  <InputMask
                    {...register("size", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    name="size"
                    mask="99х99х99см"
                    maskChar={null}
                    placeholder="Например 30х30х30см"
                  >
                    {(inputProps) => <input {...inputProps} type="text" />}
                  </InputMask>
                </>
              )}
            />
            {errors.size && <p>Укажите габариты груза</p>}
          </div>
        </div>
        <div className={style.form_group}>
          <div>
            <label>Оценочная стоимость груза:</label>
            <input
              type="number"
              {...register("object_price", {
                required: true,
                validate: (value) => value !== "",
              })}
              placeholder="до 10000 руб."
              max={10000}
            />
            {errors.object_price && <p>Укажите оценочную стоимость груза</p>}
          </div>
        </div>

        <h2>Способ оплаты:</h2>

        <div className="flex w-full mb-8">
          <div className="flex w-full justify-around ">
            {!promo && (
              <>
                <label className="mx-2">
                  <input
                    onChange={(e) => handleOnChange(e)}
                    type="radio"
                    name="payments_method"
                    defaultChecked
                    value="cash"
                  />
                  <div className="md:w-52">Курьеру</div>
                </label>
                <label className="mx-2">
                  <input
                    onChange={(e) => handleOnChange(e)}
                    type="radio"
                    name="payments_method"
                    value="card"
                    disabled={true}
                  />
                  <div className="flex flex-col md:w-52">
                    <span>Онлайн</span>
                  </div>
                </label>
              </>
            )}

            {/* <label className="mx-2">
              <input
                onChange={(e) => handleOnChange(e)}
                type="radio"
                name="payments_method"
                value="phone"
                defaultChecked={promo}
              />
              <div className="flex flex-col md:w-52">
                <span className="text-sm">Мобильный счет билайн</span>
                <span className="text-xs opacity-50"></span>
              </div>
            </label> */}
          </div>
        </div>
        {formData.adress_from &&
          formData.adress_where &&
          formData.payments_method == "cash" && (
            <>
              <h2>Оплата по адресу:</h2>
              <div className={style.where_pay}>
                <div>
                  <label>
                    <input
                      type="radio"
                      onChange={(e) => handleOnChange(e)}
                      name="payments_adress"
                      value="1"
                      defaultChecked
                    />
                    <div> 1. {formData.adress_from}</div>
                  </label>

                  <label>
                    <input
                      type="radio"
                      onChange={(e) => handleOnChange(e)}
                      name="payments_adress"
                      value="2"
                    />
                    <div> 2. {formData.adress_where}</div>
                  </label>
                </div>
              </div>
            </>
          )}
        <div className="flex flex-wrap justify-center items-end xl:justify-between">
          <Calculate formData={formData} comission={comission} />
          <div className="mt-10 xl:mt-0">
            {formData.payments_method === "phone" && (
              <Button xl type="button" onClick={() => setIsActive(true)}>
                Заказать доставку
              </Button>
            )}
            {formData.payments_method != "phone" && (
              <Button xl type={"submit"} onClick={() => onSubmit()}>
                Заказать доставку
              </Button>
            )}
          </div>
        </div>
        <Modal active={isActive} setActive={setIsActive}>
          <h3 className="text-3xl my-4">Оплата по мобильному номеру</h3>
          <div className="mb-6">
            <label>Оператор:</label>
            <div>
              <label>
                <input
                  type="radio"
                  name="payment_number"
                  value="beeline"
                  defaultChecked
                />
                <div className="w-min h-min my-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 90 90"
                    fill="none"
                    className="mr-2"
                  >
                    <path
                      d="M44.9926 89.9896C69.8379 89.9896 89.979 69.8448 89.979 44.9948C89.979 20.1449 69.8379 0 44.9926 0C20.1472 0 0.00610352 20.1449 0.00610352 44.9948C0.00610352 69.8448 20.1472 89.9896 44.9926 89.9896Z"
                      fill="#FFC800"
                    />
                    <path
                      d="M64.3526 78.5183C49.075 87.3357 32.5061 88.9159 21.9226 83.6342C35.4726 91.7095 52.8704 92.4079 67.4937 83.9659C82.1169 75.5152 90.2137 60.0979 89.9956 44.3226C89.2802 56.1344 79.6302 69.701 64.3526 78.5183Z"
                      fill="black"
                    />
                    <path
                      d="M50.6979 3.29996C53.7953 8.66023 46.3092 18.7871 33.972 25.9021C21.6347 33.0171 9.13171 34.4489 6.03431 29.0799C5.90343 28.8267 5.78128 28.5473 5.69403 28.2593C4.89133 25.7624 5.85108 22.5323 8.16323 19.1276C8.5384 18.5951 8.92231 18.0713 9.31493 17.5562C12.805 13.0078 17.2286 9.06181 22.4985 6.02375C27.7685 2.97695 33.3961 1.12617 39.0762 0.375387C39.7393 0.279356 40.3849 0.209517 41.0131 0.165867C45.1139 -0.139686 48.3858 0.646022 50.1483 2.5841C50.3577 2.81981 50.5409 3.05552 50.6979 3.29996Z"
                      fill="black"
                    />
                    <path
                      d="M69.8233 9.10545C75.2329 18.4728 64.3178 34.8941 45.4542 45.7892C26.5906 56.6844 6.90686 57.924 1.50604 48.5567C0.607359 46.994 0.127481 45.2567 0.0315055 43.3885C-0.204071 49.6828 0.877838 56.082 3.39938 62.1581C3.77456 63.0661 4.18464 63.9653 4.62089 64.8557C4.63834 64.8906 4.66452 64.9343 4.68197 64.9692C6.31356 67.789 8.72167 70.0065 11.7231 71.6128C22.3066 77.2786 40.2017 75.3318 56.9364 65.6676C73.6624 56.0034 84.2982 41.4853 84.6909 29.4902C84.8043 26.1029 84.0976 22.9164 82.4834 20.0966C81.8727 19.18 81.2357 18.2808 80.5639 17.4252C76.594 12.2919 71.6556 8.20625 66.1675 5.29041C67.6944 6.29436 68.9334 7.56022 69.8233 9.10545C69.8233 9.10545 69.8321 9.11418 69.8321 9.12291L69.8233 9.10545Z"
                      fill="black"
                    />
                    <path
                      d="M45.0016 89.9896C69.8469 89.9896 89.9881 69.8448 89.9881 44.9948C89.9881 20.1449 69.8469 0 45.0016 0C20.1563 0 0.0151367 20.1449 0.0151367 44.9948C0.0151367 69.8448 20.1563 89.9896 45.0016 89.9896Z"
                      fill="url(#paint0_radial_994:255)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_994:255"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(44.9974 44.9986) scale(44.9854 45.0112)"
                      >
                        <stop offset="0.5" stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" stopOpacity="0.35" />
                      </radialGradient>
                    </defs>
                  </svg>
                  билайн
                </div>
              </label>
            </div>
          </div>
          <div className={style.tel}>
            <label>Номер телефона:</label>
            <Controller
              control={control}
              name="pay_phone"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <PhoneInput
                    placeholder="+7 (999) 999-99-99"
                    onChange={onChange}
                    value={value}
                    country={"ru"}
                    disableDropdown={true}
                    inputProps={{
                      name: "pay_phone",
                    }}
                    inputStyle={{
                      fontFamily: "sans-serif",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                </>
              )}
            />
            {errors["pay_phone"] && <p>Введите телефон для оплаты</p>}
          </div>
          <div className="flex justify-between items-center">
            <Calculate formData={formData} />
            <Button type="submit" pay>
              Оплатить
            </Button>
          </div>
        </Modal>
      </form>
    </div>
  );
};

export default CreateOrder;
