import { HashLink as Link } from "react-router-hash-link";

import { Button, Maincalc } from "../../Components";
import { Feedback } from "../../Layouts";

import style from "./main.module.scss";

import main from "../../img/main.png";
import region from "../../img/region.png";
import payments from "../../img/payments.png";

import clothes from "../../img/delivery/clothes.png";
import docs from "../../img/delivery/docs.png";
import product from "../../img/delivery/product.png";
import gifts from "../../img/delivery/gifts.png";
import medical from "../../img/delivery/medical.png";
import all from "../../img/delivery/all.png";

const Home = () => {
  return (
    <>
      <main className={style.main}>
        <div className={style.main__container}>
          <div className={style.main__container_left}>
            <h1>Доставка курьером в кратчайшие сроки</h1>
            <p>
              Наш девиз – надежность, выгода и индивидуальный подход к каждому.
            </p>
            <Link to="/create-order">
              <Button xl>Заказать доставку</Button>
            </Link>
          </div>

          <div className={style.main__container_right}>
            {/* <img src={main} alt="Курьер возле машины" /> */}
            <Maincalc />
          </div>
        </div>
      </main>
      <section id="advantages" className={style.advantages}>
        <h2 className={style.advantages_title}>Преимущества</h2>
        <div className={style.advantages_block}>
          <div>
            <h3>35 минут</h3>
            <p>Среднее время доставки по городу</p>
          </div>
          <div>
            <h3>97 %</h3>
            <p>Успешность выполнения заявок</p>
          </div>
          <div>
            <h3>от 350 ₽</h3>
            <p>Стоимость курьерской доставки по городу</p>
          </div>
          <div>
            <h3>5 минут</h3>
            <p>
              Скорость <br /> обработки заказа
            </p>
          </div>
        </div>
      </section>
      <section id="region" className={style.region}>
        <div className={style.region_left}>
          <img src={region} alt="Регионы длоставки" />
        </div>
        <div className={style.region_right}>
          <h2>
            Надежная доставка <br /> в крупных городах России
          </h2>
          <ul>
            <li>Санкт-Петербург</li>
            <li>Нижний Новгород</li>
            <li>Екатеринбург</li>
            <li>Новосибирск</li>
            <li>Москва и Московская область</li>
          </ul>
        </div>
      </section>
      <section id="delivery" className={style.delivery}>
        <h2>Виды доставки</h2>
        <div className={style.delivery_container}>
          <div>
            <h3>Одежда</h3>
            <p>
              Доставка из магазинов <br /> и маркетплейсов
            </p>
            <img src={clothes} alt="Одежда" />
          </div>
          <div>
            <h3>Документы</h3>
            <p>
              Доставка в целости <br /> и сохранности
            </p>
            <img src={docs} alt="Документы" />
          </div>
          <div>
            <h3>Продукты</h3>
            <p>
              Доставка до дверей <br /> в термосумках
            </p>
            <img src={product} alt="Продукты" />
          </div>
          <div>
            <h3>Подарки</h3>
            <p>
              Доставка для близких <br /> и знакомых
            </p>
            <img src={gifts} alt="Подарки" />
          </div>
          <div>
            <h3>Лекарства</h3>
            <p>
              Доставим медикаменты <br /> в самые короткие сроки
            </p>
            <img src={medical} alt="Лекарства" />
          </div>
          <div>
            <h3>Всё, что угодно</h3>
            <p>
              Доставка любых <br /> товаров на ваш вкус{" "}
            </p>
            <img src={all} alt="Всё, что угодно" />
          </div>
        </div>
      </section>
      <section id="how" className={style.how}>
        <h2>Как работает?</h2>
        <div className={style.how_container}>
          <div>
            <span>Заявка</span>
            <p>
              Создайте заказ через сайт <br /> или по номеру телефона
            </p>
          </div>
          <div>
            <span>Оформление</span>
            <p>
              Получите уведомление <br /> о назначении курьера
            </p>
          </div>
          <div>
            <span>Доставка</span>
            <p>
              Курьер оперативно <br /> доставит получателю
            </p>
          </div>
          <div>
            <span>Оплата</span>
            <p>
              Произведите оплату <br /> за доставку
            </p>
          </div>
        </div>
      </section>
      <section id="tarifs" className={style.tarifs}>
        <h2>Тарифы</h2>
        <div className={style.tarifs_container}>
          <div>
            <h3>Стандарт</h3>
            <span>от 350 ₽</span>
            <ul>
              <li>В пределах города</li>
              <li>Доставка крупногабарита</li>
              <li>Доставка в ночное время</li>
            </ul>
            <Link to="/create-order">
              <Button tarifs>Заказать доставку</Button>
            </Link>
          </div>
          <div>
            <h3>Премиум</h3>
            <span>от 950 ₽</span>
            <ul>
              <li>В пределах города</li>
              <li>Доставка крупногабарита</li>
              <li>Доставка в ночное время</li>
            </ul>
            <Link to="/create-order">
              <Button tarifs>Заказать доставку</Button>
            </Link>
          </div>
          <div>
            <h3>VIP</h3>
            <span>от 1950 ₽</span>
            <ul>
              <li>В пределах города</li>
              <li>Доставка крупногабарита</li>
              <li>Доставка в ночное время</li>
            </ul>
            <Link to="/create-order">
              <Button tarifs>Заказать доставку</Button>
            </Link>
          </div>
        </div>
      </section>
      <section className={style.payments}>
        <div className={style.payments_container}>
          <div className={style.payments_container_left}>
            <h2>Способы оплаты</h2>
            <p>
              Выберите удобный для себя способ оплаты и <br /> оплатите доставку
              при передачи груза курьеру.
            </p>
            <ul>
              <li>Карта</li>
              <li>Наличные</li>
              <li className="flex">
                Мобильный номер
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 90 90"
                    fill="none"
                    className="ml-2"
                  >
                    <path
                      d="M44.9926 89.9896C69.8379 89.9896 89.979 69.8448 89.979 44.9948C89.979 20.1449 69.8379 0 44.9926 0C20.1472 0 0.00610352 20.1449 0.00610352 44.9948C0.00610352 69.8448 20.1472 89.9896 44.9926 89.9896Z"
                      fill="#FFC800"
                    />
                    <path
                      d="M64.3526 78.5183C49.075 87.3357 32.5061 88.9159 21.9226 83.6342C35.4726 91.7095 52.8704 92.4079 67.4937 83.9659C82.1169 75.5152 90.2137 60.0979 89.9956 44.3226C89.2802 56.1344 79.6302 69.701 64.3526 78.5183Z"
                      fill="black"
                    />
                    <path
                      d="M50.6979 3.29996C53.7953 8.66023 46.3092 18.7871 33.972 25.9021C21.6347 33.0171 9.13171 34.4489 6.03431 29.0799C5.90343 28.8267 5.78128 28.5473 5.69403 28.2593C4.89133 25.7624 5.85108 22.5323 8.16323 19.1276C8.5384 18.5951 8.92231 18.0713 9.31493 17.5562C12.805 13.0078 17.2286 9.06181 22.4985 6.02375C27.7685 2.97695 33.3961 1.12617 39.0762 0.375387C39.7393 0.279356 40.3849 0.209517 41.0131 0.165867C45.1139 -0.139686 48.3858 0.646022 50.1483 2.5841C50.3577 2.81981 50.5409 3.05552 50.6979 3.29996Z"
                      fill="black"
                    />
                    <path
                      d="M69.8233 9.10545C75.2329 18.4728 64.3178 34.8941 45.4542 45.7892C26.5906 56.6844 6.90686 57.924 1.50604 48.5567C0.607359 46.994 0.127481 45.2567 0.0315055 43.3885C-0.204071 49.6828 0.877838 56.082 3.39938 62.1581C3.77456 63.0661 4.18464 63.9653 4.62089 64.8557C4.63834 64.8906 4.66452 64.9343 4.68197 64.9692C6.31356 67.789 8.72167 70.0065 11.7231 71.6128C22.3066 77.2786 40.2017 75.3318 56.9364 65.6676C73.6624 56.0034 84.2982 41.4853 84.6909 29.4902C84.8043 26.1029 84.0976 22.9164 82.4834 20.0966C81.8727 19.18 81.2357 18.2808 80.5639 17.4252C76.594 12.2919 71.6556 8.20625 66.1675 5.29041C67.6944 6.29436 68.9334 7.56022 69.8233 9.10545C69.8233 9.10545 69.8321 9.11418 69.8321 9.12291L69.8233 9.10545Z"
                      fill="black"
                    />
                    <path
                      d="M45.0016 89.9896C69.8469 89.9896 89.9881 69.8448 89.9881 44.9948C89.9881 20.1449 69.8469 0 45.0016 0C20.1563 0 0.0151367 20.1449 0.0151367 44.9948C0.0151367 69.8448 20.1563 89.9896 45.0016 89.9896Z"
                      fill="url(#paint0_radial_994:255)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_994:255"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(44.9974 44.9986) scale(44.9854 45.0112)"
                      >
                        <stop offset="0.5" stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" stopOpacity="0.35" />
                      </radialGradient>
                    </defs>
                  </svg>
                </div>
              </li>
            </ul>
          </div>
          <div className={style.payments_container_right}>
            <img src={payments} alt="Способы оплаты" />
          </div>
        </div>
      </section>
      <Feedback />
    </>
  );
};

export default Home;
