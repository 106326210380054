import { useEffect, useContext, useState } from "react";
import { Controller } from "react-hook-form";

import { useForm } from "react-hook-form";
import { AddressSuggestions } from "react-dadata";
import { useCalcOrderMutation } from "../../redux/API/orderApiSlice";

import { LocationContext } from "../../Context/LocationContext";

import { Button, Spinner } from "../";

import "./maincalc.scss";

const Maincalc = () => {
  const [amoutn, setAmount] = useState(350);
  const [calcOrder, { isLoading }] = useCalcOrderMutation();
  const { city } = useContext(LocationContext);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleOnCalc = async (value) => {
    const data = {
      how_delivery: value.how_delivery,
      adress_from: value.adress_from.value,
      adress_where: value.adress_where.value,
    };
    try {
      const orderInfo = await calcOrder(data).unwrap();
      const price =
        Number(orderInfo.order.payment_amount) +
        (Number(orderInfo.order.payment_amount) / 100) * 20;
      setAmount(Math.floor(price));
      console.log(orderInfo);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    console.log(`${name}: ${value}`);
  };

  const onSubmit = async (value) => {
    await handleOnCalc(value);
  };
  return (
    <div className="maincalc">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={(e) => handleOnChange(e)}
      >
        <div className="relative">
          <Controller
            control={control}
            name="adress_from"
            render={({ field }) => (
              <AddressSuggestions
                {...register("adress_from", {
                  required: true,
                  validate: (value) => value !== "",
                  onChange: (e) => handleOnChange(e),
                })}
                {...field}
                count={5}
                filterLocations={[
                  {
                    city,
                  },
                ]}
                suggestionClassName="sug"
                suggestionsClassName="sugs"
                currentSuggestionClassName="cursug"
                containerClassName="cont"
                highlightClassName="highlight"
                inputProps={{
                  placeholder: "Откуда забрать?",
                  style: { width: "100%", outline: "none" },
                  name: "adress_from",
                }}
                token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
              />
            )}
          />

          {errors.adress_from && <p>Укажите откуда забрать посылку</p>}
        </div>
        <div className="relative mt-5">
          <Controller
            control={control}
            name="adress_where"
            render={({ field }) => (
              <AddressSuggestions
                {...register("adress_where", {
                  required: true,
                  validate: (value) => value !== "",
                  onChange: (e) => handleOnChange(e),
                })}
                {...field}
                count={5}
                filterLocations={[
                  {
                    city,
                  },
                ]}
                suggestionClassName="sug"
                suggestionsClassName="sugs"
                currentSuggestionClassName="cursug"
                containerClassName="cont"
                highlightClassName="highlight"
                inputProps={{
                  placeholder: "Откуда забрать?",
                  style: { width: "100%", outline: "none" },
                  name: "adress_where",
                }}
                token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
              />
            )}
          />
          {errors.adress_where && <p>Укажите куда привезти посылку</p>}
        </div>
        <div className="radio">
          <label>
            <input
              {...register("how_delivery")}
              defaultChecked
              type="radio"
              name="how_delivery"
              value="6"
            />
            <div>Пеший</div>
          </label>
          <label>
            <input
              {...register("how_delivery")}
              type="radio"
              name="how_delivery"
              value="7"
            />
            <div>Легковой</div>
          </label>
          <label>
            <input
              {...register("how_delivery")}
              type="radio"
              name="how_delivery"
              value="5"
            />
            <div>Грузовой</div>
          </label>
        </div>
        <div className="flex justify-between items-end mt-4">
          <div className="text-xl text-green font-bold font-sans">
            от {amoutn} руб.
          </div>
          <Button pay>
            {isLoading ? "Считаем..." : "Расчитать cтоимость"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Maincalc;
