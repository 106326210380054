import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { Button, Loading } from "../../Components";
import { useLazyGetStatusQuery } from "../../redux/API/orderApiSlice";

const Success = () => {
  const { id } = useParams();
  const [getStatus, { data, isLoading }] = useLazyGetStatusQuery();
  const navigate = useNavigate();
  const fetchStatus = async () => {
    try {
      await getStatus(id).unwrap();
      console.log(data);
    } catch (error) {
      console.log(error);
      navigate("/orders");
      toast(`${error.data.message}`, {
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [data]);

  return (
    <div className="flex justify-center">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center justify-around bg-white h-96 rounded-xl p-10">
          <svg
            width="300px"
            height="300px"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#00C6BC"
              d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"
            />
          </svg>
          <h1 className="font-bold text-3xl">Спасибо!</h1>
          <p className="text-lg text-center font-sans">
            <b> Заказ № {data && data.id}</b> на сумму{" "}
            <b>{data && data.taking_amount} руб.</b>{" "}
            {data && data.payments_method === "cash" ? "создан" : "оплачен"}!{" "}
            <br /> Как только мы назначим для Вас курьера, он с вами свяжется!
          </p>

          <div className="flex justify-around w-full mt-5">
            <Link to="/orders">
              <Button pay>Мои заказы</Button>
            </Link>
            <Link to="/">
              <Button pay>На главную</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Success;
