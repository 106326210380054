import React from "react";
import { Button } from "../../Components";

import style from "./courier.module.scss";
const Courier = () => {
  const data = [
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
    {
      order_id: 52345,
      order_date: "30.05.2023 14:30",
      order_status: "Выполнен",
      order_amount: 523,
      order_payment_mathod: "Онлайн",
      order_delivery_points: [
        "1. Кутузовский пр-кт, д. 12",
        "1. Кутузовский пр-кт, д. 12",
      ],
    },
  ];

  return (
    <div className="flex flex-col w-full h-[75vh]">
      <div className="px-8">
        <h1 className="text-3xl font-semibold text-white">Личный кабинет</h1>
      </div>
      <div className="flex p-4 h-full">
        <div className="flex flex-col p-4 w-2/6">
          <div className="p-4 mb-2 rounded-lg bg-white shadow">
            <h3 className="text-xl font-semibold">Антон Петров</h3>
            <span className="font-sans opacity-50">ID: C125123</span>
            <div className="flex items-center justify-between">
              <span className="font-sans text-lg">Баланс: 4000 руб.</span>
              <Button pay>Вывести</Button>
            </div>
          </div>
          <div className="p-4 rounded-lg bg-white h-full">
            <div className="border-b border-b-green">
              <h3 className="font-semibold">Новые заявки</h3>
            </div>
            <div className="flex flex-col p-2">
              <div className="rounded-lg shadow border border-lightgray p-4 my-2">
                <div className="flex justify-between">
                  <div className="flex flex-col my-2">
                    <span>
                      <b>Адреса:</b>
                    </span>
                    <span>1. Кутузовский пр-кт, д. 7</span>
                    <span>2. Новый Арбат д. 12</span>
                  </div>
                  <div className="flex flex-col">
                    <span>
                      <b>Сумма:</b>
                    </span>
                    <span>453 руб.</span>
                  </div>
                </div>
                <div className="my-2">
                  <span>Способ доставки: пеший</span>
                </div>
                <div className="flex justify-between my-2">
                  <Button pay>Принять</Button>
                  <Button cancel>Отклонить</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 w-4/6">
          <div className={style.sort}>
            <label>
              <input defaultChecked name="sort" type="radio" value="all" />
              <div>Все заказы</div>
            </label>
            <label>
              <input name="sort" type="radio" value="active" />
              <div>Активные</div>
            </label>
            <label>
              <input name="sort" type="radio" value="closed" />
              <div>Завершенные</div>
            </label>
          </div>
          <div>
            <table className={style.table}>
              <thead>
                <tr>
                  <th>Заказ</th>
                  <th>Дата</th>
                  <th>Статус</th>
                  <th>Сумма</th>
                  <th>Способ оплаты</th>
                  <th>Адреса доставки</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr>
                      <td>№ {item.order_id}</td>
                      <td>{item.order_date}</td>
                      <td>{item.order_status}</td>
                      <td>{item.order_amount} руб.</td>
                      <td>{item.order_payment_mathod}</td>
                      <td>
                        {item.order_delivery_points.map((point) => {
                          return <p>{point}</p>;
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courier;
