import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyCheckAuthQuery } from "../redux/API/authApiSlice";
import { setCredentials } from "../redux/authSlice";

const AuthProvider = ({ children }) => {
  const [trigger, { isLoading }] = useLazyCheckAuthQuery();
  const dispatch = useDispatch();

  const checkAuth = async () => {
    try {
      const data = await trigger().unwrap();
      dispatch(setCredentials(data));
    } catch (error) {
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return children;
};

export default AuthProvider;
