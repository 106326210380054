import { useEffect, useState } from "react";
import format from "date-fns/format";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { OrderTable } from "../../Components";
import {
  useLazyGetOrdersQuery,
  useCancelOrderMutation,
  usePayOrderMutation,
} from "../../redux/API/orderApiSlice";

import { Button, Loading } from "../../Components";
import style from "./orders.module.scss";

const Orders = () => {
  const [getOrders, { data = [], isLoading: isGetOrdersLoading }] =
    useLazyGetOrdersQuery();
  const [cancelOrder, { isLoading: isCancelOrderLoading }] =
    useCancelOrderMutation();

  const [pay, { isLoading }] = usePayOrderMutation();

  const [width, setWidth] = useState(document.documentElement.clientWidth);

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [sort, setSort] = useState("all");

  const sortOrders = () => {
    if (sort === "all") {
      setFilteredOrders(orders);
    } else if (sort === "active") {
      setFilteredOrders(orders.filter((item) => item.status !== "canceled"));
    } else if (sort === "closed") {
      setFilteredOrders(orders.filter((item) => item.status === "completed"));
    }
  };

  const queryOrders = async () => {
    const data = await getOrders().unwrap();
    setOrders(data);
    setFilteredOrders(data);
  };

  const onCancelOrder = async (order_id) => {
    const id = toast.loading("Отменяем заказ");
    try {
      await cancelOrder({ order_id }).unwrap();
      queryOrders();
      toast.update(id, {
        render: "Заказ отменен 😓",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (error) {
      toast.update(id, {
        render: "Упс, что-то пошло не так...🤔",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const onPayOrder = async (order_id) => {
    const id = toast.loading("Инициализируем оплату");

    try {
      const data = await pay(order_id).unwrap();
      console.log(data);

      if (data["redirect-url"]) {
        window.location.replace(data["redirect-url"]);
      }
    } catch (error) {
      toast.update(id, {
        render: "Упс, что-то пошло не так...🤔 Повторите попытку позже!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    const handleResizeWindow = () =>
      setWidth(document.documentElement.clientWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);

  useEffect(() => {
    sortOrders();
  }, [sort, data]);

  useEffect(() => {
    queryOrders();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="flex justify-between mb-8">
        <h1 className="text-white text-5xl font-semibold">Заказы</h1>
      </div>
      <div className="flex justify-between items-center">
        {data.length ? (
          <div onChange={(e) => setSort(e.target.value)} className={style.sort}>
            <label>
              <input defaultChecked name="sort" type="radio" value="all" />
              <div>Все заказы</div>
            </label>
            <label>
              <input name="sort" type="radio" value="active" />
              <div>Активные</div>
            </label>
            <label>
              <input name="sort" type="radio" value="closed" />
              <div>Завершенные</div>
            </label>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col justify-center items-center mt-5 mb-56">
        {isGetOrdersLoading || isCancelOrderLoading ? (
          <Loading />
        ) : (
          <>
            {sort === "all" && (
              <>
                {filteredOrders.length ? (
                  <OrderTable
                    orders={filteredOrders}
                    onCancelOrder={onCancelOrder}
                    onPayOrder={onPayOrder}
                  />
                ) : (
                  <div className="flex flex-col justify-center items-center h-[30vh]">
                    <span className="text-gray text-3xl font-bold mb-12">
                      Вы еще не сделали ни одного заказа
                    </span>
                    <Link to="/create-order">
                      <Button xl>Заказать доставку</Button>
                    </Link>
                  </div>
                )}
              </>
            )}
            {sort === "active" && (
              <>
                {filteredOrders.length ? (
                  <OrderTable
                    orders={filteredOrders}
                    onCancelOrder={onCancelOrder}
                    onPayOrder={onPayOrder}
                  />
                ) : (
                  <div className="flex flex-col justify-center items-center h-[30vh]">
                    <span className="text-gray text-3xl font-bold mb-12">
                      Активных заказов нет
                    </span>
                  </div>
                )}
              </>
            )}
            {sort === "closed" && (
              <>
                {filteredOrders.length ? (
                  <OrderTable
                    orders={filteredOrders}
                    onCancelOrder={onCancelOrder}
                  />
                ) : (
                  <div className="flex flex-col justify-center items-center h-[30vh]">
                    <span className="text-gray text-3xl font-bold mb-12">
                      Завершенных заказов нет
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Orders;
