import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { setCredentials } from "../redux/authSlice";
import {
  useCreateOrderMutation,
  useCalcOrderMutation,
} from "../redux/API/orderApiSlice";
import { useSendOrderMailMutation } from "../redux/API/mailApiSlice";

import { LocationContext } from "../Context/LocationContext";

import { CreateOrder } from "../Pages";

const CreateOrderContainer = ({ promo, comission }) => {
  const user = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    payments_method: promo ? "phone" : "cash",
    taking_amount: "250",
    time_delivery: "Как можно скорее",
    how_delivery: "6",
    total_weight: 1,
    payments_adress: 1,
  });

  const [createOrder] = useCreateOrderMutation();
  const [calcOrder] = useCalcOrderMutation();
  const [sendOrderMail] = useSendOrderMailMutation();

  const { city } = useContext(LocationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    // console.log(`${name}: ${value}`);

    if (user) {
      setFormData({
        ...formData,
        [name]: value.value || value,
        customer_firstname: user.firstname,
        customer_lastname: user.lastname,
        customer_middlename: user.middlename,
        customer_phone: user.phone,
        email: user.email,
      });
    } else {
      setFormData({ ...formData, [name]: value.value || value });
    }
  };

  const handleOnCalc = async () => {
    try {
      const orderInfo = await calcOrder(formData).unwrap();
      setFormData({
        ...formData,
        taking_amount: Math.floor(
          Number(orderInfo.order.payment_amount) +
            (comission == 20 ? 72 : 0) +
            (Number(orderInfo.order.payment_amount) / 100) * comission
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     setFormData({
  //       ...formData,
  //       customer_firstname: user.firstname,
  //       customer_lastname: user.lastname,
  //       customer_middlename: user.middlename,
  //       customer_phone: user.phone,
  //       email: user.email,
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    let timeout;
    timeout = setTimeout(handleOnCalc, 1000);
    return () => clearTimeout(timeout);
  }, [
    formData.adress_from,
    formData.adress_where,
    formData.total_weight,
    formData.object_price,
  ]);

  const onSubmit = async () => {
    // console.log(formData);
    const id = toast.loading("Фромируем заказ...");
    try {
      const data = await createOrder(formData).unwrap();
      // console.log("Response:", data);

      if (formData.payments_method === "cash") {
        if (data.accessToken) {
          dispatch(setCredentials(data));
        }
        if (!data.accessToken && data.order.id) {
          navigate(`/orders/success/${data.order.id}`);
        }
        toast.update(id, {
          render: "Заказ сформирован!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      }

      if (formData.payments_method === "card") {
        if (data.accessToken) {
          dispatch(setCredentials(data));
        }

        if (data.data && data.data["redirect-url"]) {
          window.location.replace(data.data["redirect-url"]);
        } else {
          toast.update(id, {
            render: "Упс, что-то пошло не так... Скоро все заработает!",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
          // navigate(`/orders`);
        }
      }

      if (formData.payments_method === "phone") {
        if (data.accessToken) {
          dispatch(setCredentials(data));
        }
        toast.info("Следуйте за смс от оператора!");
        toast.update(id, {
          render: "Заказ сформирован!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        navigate(`/orders`);
      }
    } catch (error) {
      console.log(error);
      toast.update(id, {
        render:
          "Упс! Что-то пошло не так... Попробуйте обновить страницу и ввести данные еще раз!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  return (
    <CreateOrder
      comission={comission}
      promo={promo}
      formData={formData}
      setFormData={setFormData}
      handleOnChange={handleOnChange}
      onSubmit={onSubmit}
      city={city}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      reset={reset}
      errors={errors}
      user={user}
    />
  );
};

export default CreateOrderContainer;
